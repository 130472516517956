<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('iam.userDetails.title') }}
      </h2>
    </div>

    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div style="width:100%; height:30vh;" class="flex justify-center" v-if="loading">
        <LoadingIcon icon="grid" color="#2D3748" style="width:100px" />
      </div>
      <div v-if="record && !loading">
        <div class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5">
          <div class="flex flex-1 px-5 items-center justify-center lg:justify-start">
            <div class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-full"
                data-action="zoom"
                :src="record.avatar ? record.avatar : '/images/default-avatar.svg'"
              />
                <!-- :src="record.avatar ? record.avatar : require(`@/assets/images/${$f()[0].photos[0]}`)" -->
            </div>
            <div class="ml-5 mr-5">
              <div class="w-24 sm:w-40 sm:whitespace-normal font-medium text-lg">
                {{ record.userName }}
              </div>
            </div>
          </div>
          <div class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0">
            <div class="font-medium text-center lg:mt-3" :class="isRTL ? 'lg:text-right' : 'lg:text-left'">
              {{ i18n('iam.userDetails.contactDetails') }}
            </div>

            <address class="flex flex-col justify-center items-center lg:items-start mt-4">
              <div class="truncate sm:whitespace-normal flex items-center gap-2">
                <MailIcon class="w-4 h-4" />
                <a :href="`mailto:${record.email}`">{{ record.email ? record.email : 'ـــــ' }}</a>
              </div>
              <div class="truncate sm:whitespace-normal flex items-center gap-2 mt-3">
                <PhoneIcon class="w-4 h-4" :style="isRTL ? 'transform: rotateY(180deg)' : 'transform: rotateY(0deg)'" />
                <span style="direction:ltr !important">
                  <a :href="`tel:${record.phoneNumber}`">{{ record.phoneNumber ? record.phoneNumber : 'ـــــ' }}</a>
                </span>
              </div>
            </address>

            <div class="flex flex-col justify-center items-center lg:items-start mt-4">
              <details>
                <summary class="text-center" :class="isRTL ? 'lg:text-right' : 'lg:text-left'">{{ i18n('iam.userDetails.addresses') }}</summary>
                <address class="mt-2" v-if="addresses && addresses.length">
                  <ul class="px-5" style="list-style-type: square;">
                    <!-- :class="{ 'text-theme-10': address.isDefault }" -->
                    <li v-for="(address, index) in addresses" :key="index" class="text-gray-600">
                      <div>{{ address.name }} , {{ address.city[language] }} <strong v-if="address.isDefault">({{ i18n('common.default') }})</strong></div>
                      <div>{{ i18n('iam.userDetails.description') }} : <strong>{{ address.description }}</strong></div>
                      <div>{{ i18n('iam.userDetails.phoneNumber') }} : <strong><a :href="`tel:${address.phoneNumber}`">{{ address.phoneNumber }}</a></strong></div>
                      <br v-if="index < addresses.length - 1">
                    </li>
                  </ul>
                </address>
                <div class="mt-2 px-5 text-gray-500" v-else>{{ i18n('iam.userDetails.addressNotFound') }}</div>
              </details>
              <!-- <div class="font-bold text-center mb-2">{{ i18n('iam.userDetails.addresses') }}</div>
              <address>
                <ul class="px-5">
                  <li v-for="(address, index) in addresses" :key="index">
                    <div :class="{ 'text-theme-10': address.isDefault }">{{ address.name }} , {{ address.city[language] }}</div>
                    <div>{{ i18n('iam.userDetails.description') }} : <strong>{{ address.description }}</strong></div>
                    <div>{{ i18n('iam.userDetails.phoneNumber') }} : <strong><a :href="`tel:${address.phoneNumber}`">{{ address.phoneNumber }}</a></strong></div>
                    <br>
                  </li>
                </ul>
              </address> -->
            </div>

            <div class="font-medium text-center mt-3" :class="isRTL ? 'lg:text-right' : 'lg:text-left'">
              {{ i18n('iam.userDetails.status') }}
            </div>
            <div class="flex flex-col justify-center items-center lg:items-start mt-2">
              <div
                class="truncate sm:whitespace-normal flex items-center"
                :class="{
                  'text-theme-10 dark:text-theme-30': row.status == 'pending',
                  'text-green-600': row.status == 'accepted',
                  'text-theme-24': !activated
                }"
              >
                {{ activated ? i18n('common.activated') : i18n('common.disabled') }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start" role="tablist">
          <a
            id="orders-tab"
            data-toggle="tab"
            data-target="#orders"
            href="javascript:;"
            class="py-4 sm:mr-8 active"
            role="tab"
            aria-controls="orders"
            aria-selected="true"
            @click="tab='orders'"
          >
            {{ i18n('iam.userDetails.orders') }}
          </a>
          <a
            id="notifications-tab"
            data-toggle="tab"
            data-target="#notifications"
            href="javascript:;"
            class="py-4 sm:mr-8"
            role="tab"
            aria-selected="false"
            @click="tab='notifications'"
          >
            {{ i18n('iam.userDetails.notifications') }}
          </a>
        </div> -->
      </div>
    </div>
    <!-- END: Profile Info -->

    <!-- <div class="intro-y tab-content mt-5">
      <div id="orders" class="tab-pane active" role="tabpanel" aria-labelledby="orders-tab" v-if="tab == 'orders'">
        <OrdersTab :id="id" />
      </div>

      <div id="notifications" class="tab-pane active" role="tabpanel" aria-labelledby="notifications-tab" v-if="tab == 'notifications'">
        <NotificationsTab :id="id" />
      </div>
    </div> -->
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import OrdersTab from '@/views/users/orders-tab.vue'
// import NotificationsTab from '@/views/users/notifications-tab.vue'

import { UserModel } from '@/store/user/user-model'
const { fields } = UserModel

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    }
  },
  // components: {
  //   OrdersTab,
  //   NotificationsTab
  // },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'user/view/record',
      loading: 'user/view/loading'
    }),
    fields() {
      return fields
    },
    activated() {
      return this.record && this.record.status === 'enabled'
    }
  },
  setup() {
    const tab = ref('orders')

    return {
      tab
    }
  },
  created() {
    document.title = this.i18n('app.title') + ' | ' + this.i18n('routes.users')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('app.title') + ' | ' + this.i18n('routes.users')
    }
  },
  async mounted() {
    this.doSetUserType('user')
    await this.doFind(this.id)
  },
  methods: {
    ...mapActions({
      doSetUserType: 'user/list/doSetUserType',
      doFind: 'user/view/doFindUser'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
